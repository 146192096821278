import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled, Modal, Select, MenuItem, TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import DataTable from "../../components/Table";
import { useGridApiRef } from "@mui/x-data-grid-pro";

moment.locale('it');
const localizer = momentLocalizer(moment)

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#888',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListCertificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Titolo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'sotto_categoria', headerName: 'Categoria', width: 200, headerClassName: 'data-grid-header' },
        { field: 'category', headerName: 'Classe', width: 200, headerClassName: 'data-grid-header' },
        { field: 'requiredNavigation', headerName: 'Numero mesi totali', width: 200, headerClassName: 'data-grid-header' },
        { field: 'numero_mesi_grado', headerName: 'Numero mesi da grado', width: 200, headerClassName: 'data-grid-header' },
        { field: 'role_required_name', headerName: 'Grado Necessario', width: 200, headerClassName: 'data-grid-header'},
        { field: 'imo', headerName: 'Necessita IMO', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => params.row.imo ? 'Sì' : 'No'},
        { field: 'exam', headerName: 'Necessita esame', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => params.row.exam ? 'Sì' : 'No'},
    
      ];
    const getCertificates = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const res = await (await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path`)).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('courses.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);

    }
    const [open, setOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    

    const tableRef = useGridApiRef();
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
       <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
          <Box sx={style}>
          {selectedRole && <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Padre
          </Typography>
          <Select value={selectedRole.parent} onChange={(e) => {
            setSelectedRole({...selectedRole, parent: e.target.value})
          }}>
            {certificates.map(x => (
              <MenuItem value={x.id}>{x.name}</MenuItem>
            ))}
          </Select>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ruolo richiesto
          </Typography>
          <Select value={selectedRole.role_required} onChange={(e) => {
            setSelectedRole({...selectedRole, role_required: e.target.value})
          }}>
            {certificates.map(x => (
              <MenuItem value={x.id}>{x.name}</MenuItem>
            ))}
          </Select>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Numero mesi navigazione totale
          </Typography>
          <TextField variant="outlined" value={selectedRole.requiredNavigation} onChange={(e) => setSelectedRole({...selectedRole, requiredNavigation: e.target.value})}></TextField>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Numero mesi navigazione da grado
          </Typography>
          <TextField variant="outlined" value={selectedRole.numero_mesi_grado} onChange={(e) => setSelectedRole({...selectedRole, numero_mesi_grado: e.target.value})}></TextField>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tonnellata necessaria
          </Typography>
          <TextField variant="outlined" value={selectedRole.tonnellata_necessaria} onChange={(e) => setSelectedRole({...selectedRole, tonnellata_necessaria: e.target.value})}></TextField>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            URL Esame a valle
          </Typography>
          <TextField variant="outlined" value={selectedRole.url} onChange={(e) => setSelectedRole({...selectedRole, url: e.target.value})}></TextField>
          <FormGroup>
            <FormControlLabel control={<Checkbox/>} checked={selectedRole.imo} value={selectedRole.imo} onChange={(e, nv) => setSelectedRole({...selectedRole, imo: nv})} label="IMO" />
            <FormControlLabel control={<Checkbox/>} checked={selectedRole.exam} value={selectedRole.exam} onChange={(e, nv) => setSelectedRole({...selectedRole, exam: nv})} label="Esame" />
          </FormGroup>
          <Button variant="contained" onClick={async() => {
            setLoading(true);
            await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/${selectedRole.id}`, {
              method: 'PUT',
              body: JSON.stringify(selectedRole)
            });
            await getCertificates();
            setLoading(false);
            setOpen(false);
          }}>Salva</Button>
          </>}


        </Box>
        
        
      </Modal>
      <div style={{width: '100%'}}>
        <Button sx={{alignSelf: 'flex-end'}} onClick={(e) => {
          console.log('Ruolo da cambiare', selectedRole);
          setOpen(true);
        }}>Modifica grado</Button>
      </div>
      <DataTable onSelectionChange={(e) => setSelectedRole(certificates.filter(x => x.id === e[0])[0])} data={certificates} ref={tableRef} columns={columns} handleClick={handleClick} actions={[
        {type: 'select', name: 'Azioni'}
        ]}
        onClick={{
          action: 'navigate',
          path: ':id'
        }}
        />
    
    </Container>);
}

export default ListCertificates;