// import ListLeads from "../views/pages/leads/ListLeads";
// import AddLead from "../views/pages/leads/AddLead";

import Home from '../views/pages/index/Index'
import ListCourses from "../views/pages/courses/ListCourses";
import ListTeachers from "../views/pages/teachers/ListTeachers";
import DetailCP from "../views/pages/courses/RoleDetail";

const ChildRouter = [
    {
        path: '/',
        element: <Home />,
        title: 'Index'
    },
    {
        path: '/dashboard',
        element: <Home />,
        title: 'Index'
    },
    {
        path: '/career-path',
        element: <ListCourses />,
        title: 'Lista corsi',
        group: 'Corsi'
    },
    {
        path: '/career-path/:id',
        element: <DetailCP />,
        title: 'Lista corsi',
        group: 'Corsi'
    },
    {
        path: '/imbarchi',
        element: <ListTeachers />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
    {
        path: '/centri',
        element: <ListTeachers />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
   
];

export { ChildRouter };