import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled, Modal, Select, MenuItem, TextField, FormGroup, FormControlLabel, Checkbox, Grid, Card, CardHeader, CardContent } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';

import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import { useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import DataTable from "../../components/Table";
import { useGridApiRef } from "@mui/x-data-grid-pro";

moment.locale('it');
const localizer = momentLocalizer(moment)

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#888',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));


  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListCertificates = () => {
    const [certificates, setCertificates] = useState({});


    const [courses, setCourses] = useState([]);

    const navigate = useNavigate();

    const elements = useSelector(state => state.elements);
    let { id } = useParams('id');
 
    const getCertificates = async() => {
        const res = await (await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/${id}`)).json();
        setCertificates({...res});
    }

    const getCourses = async() => {
      const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates`)).json()
      setCourses([...res]);
    }
    useEffect(() => {
        getCertificates();
        getCourses();
    }, [])

    const [open, setOpen] = useState(false);
    
    const columnsCourses = [{ field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
      { field: 'nome corso', headerName: 'Titolo corso', width: 200, headerClassName: 'data-grid-header' },
      { field: 'validita\'', headerName: 'Validità (anni)', width: 100, headerClassName: 'data-grid-header' },
      { field: 'obbligatorieta\'', headerName: 'Obbligatorietà', width: 200, headerClassName: 'data-grid-header' },
    ]
    const columnsInformative = [{ field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
      { field: 'title', headerName: 'Titolo informativa', width: 350, headerClassName: 'data-grid-header' },
      { field: 'description', headerName: 'Descrizione', width: 350, headerClassName: 'data-grid-header' },
    ]
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedInformativa, setSelectedInformativa] = useState({});
    const [openModalCourse, setOpenModalCourse] = useState(false);

    const [newCourse, setNewCourse] = useState(null);

    const [newInformativa, setNewInformativa] = useState({});

    const style = {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    const [openModalInformative, setOpenModalInformative] = useState(false);
    
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
      <Modal
        open={openModalCourse}
        onClose={() => setOpenModalCourse(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
          <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Padre
          </Typography>
          <Select value={newCourse?.id} onChange={(e) => {
            setNewCourse(courses.filter(x => x.id === e.target.value)[0]);
          }}>
            {courses.map(x => (
              <MenuItem value={x.id}>{x['nome corso']}</MenuItem>
            ))}
          </Select>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mt: 2}}>
            Obbligatorietà
          </Typography>
          <Select sx={{mb: 2}} value={newCourse?.['obbligatorieta\'']} onChange={(e) => {
            setNewCourse({...newCourse, ['obbligatorieta\'']: e.target.value});
          }}>
            <MenuItem value={"true"}>OBBLIGATORIO</MenuItem>
            <MenuItem value={"PROPEDEUTICO"}>PROPEDEUTICO</MenuItem>
            <MenuItem value={"ADDIZIONALE"}>ADDIZIONALE</MenuItem>
          </Select>
          <Button variant="contained" onClick={async() => {
            console.log(JSON.stringify({...newCourse}));
            await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/${id}`, {
              method: 'POST',
              body: JSON.stringify({...newCourse})
            });
            await getCertificates();
            setOpenModalCourse(false);
          }}>Salva</Button>


        </Box>
        
        
      </Modal>
      <Modal
        open={openModalInformative}
        onClose={() => setOpenModalInformative(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Titolo informativa
            </Typography>
            <TextField variant="outlined" value={newInformativa.title}  onChange={(e) => setNewInformativa({...newInformativa, title: e.target.value})}></TextField>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Descrizione informativa
            </Typography>
            <TextField variant="outlined" value={newInformativa.description}  onChange={(e) => setNewInformativa({...newInformativa, description: e.target.value})}></TextField>

          <Button variant="contained" onClick={async() => {
            await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/informative/${id}`, {
              method: 'POST',
              body: JSON.stringify({...newInformativa})
            });
            await getCertificates();
            setOpenModalInformative(false);
          }}>Salva</Button>


        </Box>
        
        
      </Modal>
      {Object.keys(certificates).length > 0 && 
        <Grid container columnGap={12}>
          <Grid item xs={5}>
            <h1 style={{textAlign: 'left'}}>Corsi</h1>
            <Button variant="contained" onClick={() => {
              setOpenModalCourse(true)
              
              }}>Aggiungi corso</Button>
            <Button disabled={!selectedCourse || Object.keys(selectedCourse) <= 0} variant="contained" color="error" sx={{marginLeft: 24}} onClick={async (e) => {
                await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/${selectedCourse}`, {
                  method: 'DELETE',
                });
                await getCertificates();
                setOpenModalCourse(false);}}
              >Elimina Corso</Button>
            <DataTable onSelectionChange={(e) => setSelectedCourse(e[0])} data={certificates.courses} columns={columnsCourses} 
            />
          </Grid>
          <Grid item xs={5}>
            <h1 style={{textAlign: 'left'}}>Variante</h1>
            {certificates.variant.length > 0 && <Card>
              <CardHeader title={certificates.variant[0]?.name}/>
              <CardContent style={{textAlign: 'left'}}>
                <ul>
                  <li>Numero mesi navigazione totale: {certificates.variant[0]?.total_navigation}</li>
                  <li>Numero mesi navigazione da {certificates.variant[0]?.name}: {certificates.variant[0]?.navigationMonths}</li>
                  <li>GRT/Kw: {certificates.variant[0]?.grt}</li>
                </ul>
              </CardContent>
            </Card>}
            
          </Grid>
          <Grid item xs={5}>
            <h1 style={{textAlign: 'left'}}>Informative</h1>
            <Button variant="contained" onClick={() => {
              setOpenModalInformative(true);
            }}>Aggiungi informativa</Button>
            <Button disabled={!selectedInformativa || Object.keys(selectedInformativa) <= 0} variant="contained" color="error" sx={{marginLeft: 24}} onClick={async (e) => {
                await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/informative/${selectedInformativa.informativa_single_id}`, {
                  method: 'DELETE',
                });
                await getCertificates();
                setOpenModalCourse(false);}}
              >Elimina informativa</Button>
            <DataTable onSelectionChange={(e) => setSelectedInformativa(certificates.informatives.filter(x => x.id === e[0])[0])} data={certificates.informatives} columns={columnsInformative} 
            />
          </Grid>
        </Grid>
      }
      
    
    </Container>);
}

export default ListCertificates;